<template>
  <div>
    <header>
      <div id="logo">
        LiveEasy
      </div>

      <ul>
        <li
          v-for="h in headers"
          :key="h.link"
          :class="{ selected: $route.path === h.link }"
        >
          <router-link
            :to="h.link"
          >
            {{ h.text }}
          </router-link>
        </li>
      </ul>
    </header>

    <router-view />

    <footer>© 2021 by LiveEasy</footer>
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    headers: [
      { text: 'Home', link: '/' },
      { text: 'Privacy Policy', link: '/privacy-policy' },
    ]
  }),
}
</script>

<style lang="scss">
body {
  margin: 0;
  overflow: overlay;
  background-color: rgb(254, 237, 155);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  display: flex;

  &>* {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;

  #logo {
    flex: 1;
    text-align: left;
    padding-left: 40px;
    font-size: 1.2em;
    font-weight: bold;
  }

  ul, li {
    display: inline-block;
  }

  li {
    &.selected {
      border-bottom: solid 3px rgb(27, 105, 228);
    }

    a {
      padding: 10px;
      text-decoration: none;

      &:hover {
        color: rgb(27, 105, 228) !important;
      }

      &:visited {
        color: inherit;
      }
    }
  }
}

.content {
  flex: 1;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
}

footer {
  text-align: left;
  padding: 20px;
}

@media only screen and (max-width: 950px) {
  .content {
    padding: 0 20px;
  }
}
</style>
