<template>
  <div class="content">
    <div class="hero">
      <h1>
        Live easy with <br />CookEasy<br />
        <small>Simplifying your daily tasks so you can focus on what really matters.</small>
      </h1>

      <div class="phone">
        <img src="@/assets/cookeasy_screenshot_1.webp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.content {
  justify-content: space-between;
}
.hero {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .phone {
    margin-top: 40px;
    margin-right: 60px;
    height: 520px;
    width: 242px;
    min-height: 520px;
    min-width: 242px;
    border-radius: 30px;
    overflow: hidden;
    border: solid 8px #eee;

    img {
      height: 520px;
      width: 242px;
      object-fit: cover;
    }
  }

  h1 {
    font-size: 2.5em;

    small {
      font-size: 0.5em;
    }
  }
}

@media only screen and (max-width: 950px) {
  .hero {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    .phone {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .hero {
    .phone {
      margin-right: 0;
    }
  }
}

</style>
